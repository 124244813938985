import React from 'react';
import Seo from "../components/seo";
import { Container, Row, Col } from 'react-bootstrap';
import TopDarkRelative from "../components/Modules/Top/TopDarkRelative";
import Footer from "../components/Modules/FooterPage"


const Sout = () => {
    return (
    <>
        <Seo 
        title="Размещение результатов СОУТ"
        description="Экспертная компания в холодоснабжении Рефинжиниринг. Собственное производство и сервисная служба."
        keywords = "Рефинжиниринг, соут"
        />
        <Container fluid>
            <Row>
                <Col>
                <TopDarkRelative />
                </Col>
            </Row>
            <Row>
                <Col style={{marginLeft: "5vw", marginTop: "5vh"}}>
                    <h2>Размещение результатов СОУТ</h2>
                </Col>
            </Row>
            <Row style={{minHeight: "50vh"}}>
                <Col style={{marginLeft: "2vw", marginTop: "5vh"}}>
                    <ul>
                        <li><a style={{cursor: 'pointer'}} href="https://drive.google.com/file/d/1Z9jPi7pUu4WXV0EwuRTWBDNJRezXDRZw/view?usp=sharing" target="_blank" rel="noopener noreferrer">Заключение эксперта по результатам специальной оценки условий труда</a></li>
                        <li><a style={{cursor: 'pointer'}} href="https://drive.google.com/file/d/1QtGgUFVJrNsazKcoS9Cpk8g9jmuYs074/view?usp=sharing" target="_blank" rel="noopener noreferrer">Перечень рекомендуемых мероприятий по улучшению условий труда</a></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col style={{padding: '0'}}>
                    <Footer />
                </Col>
            </Row>
        </Container>
    </>
    )
}

export default Sout